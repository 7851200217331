import React from 'react'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'
import { IStrapiFooter } from '../../types/strapi'
import FooterSection from './footer-section/FooterSection'
import useSectionTheme from '../../hooks/useSectionTheme'
import ImageLink from '../image-link'
import FooterMail from './footer-mail/FooterMail'
import FooterAddress from './footer-address/FooterAddress'
import { FooterContainer, FooterCopyright, FooterWrapper } from './Footer.styled'
import SocialsBlockLazy from '../socials-block/SocialsBlockLazy'
import FooterLink from './footer-link/FooterLink'

export interface IFooterViewProps extends IStrapiFooter { }

const FooterView: React.FC<IFooterViewProps> = (props) => {
  const {
    theme,
    logo,
    email,
    addresses,
    sections,
    commonLinks,
    copyright,
    socials,
    socialProps,
  } = props

  const sectionTheme = useSectionTheme(theme)

  return (
    <ThemeProvider theme={sectionTheme}>
      <FooterWrapper>
        <FooterContainer sx={{ pb: 0, pt: 8 }}>
          <Grid container columns={{ xs: 10, md: 12, lg: 10 }} columnSpacing={{ xs: 10, md: 8, xl: 10 }}>
            <Grid item xs={10} md={4} lg={6}>
              <ImageLink {...logo} sx={{ height: 52 }} />
              {email && (
                <Box mb={{ xs: 6, md: 0 }}>
                  <FooterMail to={`mailto:${email}`}>{email}</FooterMail>
                </Box>
              )}
            </Grid>

            {addresses.map((address) => (
              <Grid key={address.title} item xs={5} md={4} lg={2} display='flex'>
                <FooterAddress {...address} sx={{ pt: { xs: 3, md: 0 }, pb: { xs: 5, md: 0 } }} />
              </Grid>
            ))}
          </Grid>
        </FooterContainer>

        <Divider sx={{ backgroundColor: '#465068' }} />

        <FooterContainer sx={{ pb: 6 }}>
          <Grid container columns={{ xs: 12, sm: 12, md: 12, lg: 10 }} columnSpacing={{ xs: 10, md: 8, xl: 10 }} rowSpacing={{ xs: 6, md: 8, xl: 10 }}>
            {sections.map((section, index) => (
              <Grid key={index} item component='nav' xs={12} sm={6} md={4} lg={2}>
                <FooterSection {...section} />
              </Grid>
            ))}
          </Grid>
        </FooterContainer>

        <Divider sx={{ backgroundColor: '#465068' }} />

        <FooterContainer sx={{ py: 6 }}>
          <Grid container columns={10} spacing={{ xs: 6, xl: 10 }} flexDirection={{ xs: 'column', lg: 'row' }} alignItems='center'>
            <Grid item xs={10} lg={4}>
              <Stack direction='row' spacing={{ xs: 4, sm: 8 }}>
                {commonLinks.map((link, index) => (
                  <FooterLink key={index} {...link} sx={{ fontSize: { xs: 12 } }} />
                ))}
              </Stack>
            </Grid>

            <Grid item xs={10} lg={2} order={{ lg: 10 }}>
              <SocialsBlockLazy socials={socials} socialProps={socialProps} />
            </Grid>

            <Grid item xs={10} lg={4}>
              <FooterCopyright variant='body2'>{copyright}</FooterCopyright>
            </Grid>
          </Grid>
        </FooterContainer>
      </FooterWrapper>
    </ThemeProvider>
  )
}

export default FooterView

import { TypographyProps } from '@mui/material/Typography'
import { IStrapiFooterLink } from '../../../types/strapi'
import Link from '../../link'
import { LinkText } from './FooterLink.styled'
import transformStrapiLink from '../../../utils/transformStrapiLink'

export type IFooterLinkProps = IStrapiFooterLink & TypographyProps

const FooterLink = (props: IFooterLinkProps) => {
  const { label, link, isTitle, color, ...rest } = props

  return (
    <LinkText
      {...(link && { component: Link, ...transformStrapiLink(link) })}
      {...rest}
    >
      {label}
    </LinkText>
  )
}

export default FooterLink

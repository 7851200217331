import React, { useCallback, useRef, useState } from 'react'
import { ISectionData } from '../../utils/renderSection'

export interface ISectionModifiers {
  extraTopPadding?: number
  extraBottomPadding?: number
}

export interface ISectionsContext {
  sections: Array<ISectionData>
  layoutSections: Array<ISectionData>
  modifiersMap: Map<ISectionData, ISectionModifiers>
  updateModifiersMap: (section: ISectionData, modifiers: ISectionModifiers) => void
}

export const SectionsContext = React.createContext<ISectionsContext>({
  sections: [],
  layoutSections: [],
  modifiersMap: new Map(),
  updateModifiersMap: () => {
    throw new Error('updateModifiersMap not defined')
  },
})

export interface ISectionsProviderProps extends React.PropsWithChildren {
  sections: Array<ISectionData>
  layoutSections?: Array<ISectionData>
}

export const SectionsProvider = ({ sections, layoutSections, children }: ISectionsProviderProps) => {
  const [ modifiersMap, setModifiersMap ] = useState(new Map())

  const layoutSectionsRef = useRef(layoutSections || [])

  const updateModifiersMap = useCallback((section: ISectionData, modifiers: ISectionModifiers) => {
    setModifiersMap(prevMap => new Map(prevMap.set(section, { ...prevMap.get(section), ...modifiers })))
  }, [])

  return (
    <SectionsContext.Provider value={{ sections, layoutSections: layoutSectionsRef.current, modifiersMap, updateModifiersMap }}>
      {children}
    </SectionsContext.Provider>
  )
}

import { useCallback, useContext } from 'react'
import { ISectionModifiers, SectionsContext } from '../contexts/sections-provider/SectionsProvider'
import { ISectionData } from '../utils/renderSection'

export const useSectionsContext = () => {
  const { sections, layoutSections, modifiersMap, updateModifiersMap } = useContext(SectionsContext)

  const setPrevSectionModifiers = useCallback((modifiers: ISectionModifiers, currentSection: ISectionData) => {
    const index = layoutSections.findIndex(section => section === currentSection)
    const prevSection = layoutSections[index - 1]
    updateModifiersMap(prevSection, modifiers)
  }, [ layoutSections, updateModifiersMap ])

  const setNextSectionModifiers = useCallback((modifiers: ISectionModifiers, currentSection: ISectionData) => {
    const index = layoutSections.findIndex(section => section === currentSection)
    const nextSection = layoutSections[index + 1]
    updateModifiersMap(nextSection, modifiers)
  }, [ layoutSections, updateModifiersMap ])

  return {
    sections,
    modifiersMap,
    setPrevSectionModifiers,
    setNextSectionModifiers,
  }
}

import { IStrapiButton } from '../../types/strapi'
import DownloadButton from './DownloadButton'
import LinkButton from './LinkButton'
import { Button as MuiButton, ButtonProps } from '@mui/material'
import withPromoWrapper from './withPromoWrapper'

export type IButtonProps =
ButtonProps
& Omit<IStrapiButton, 'label'>
& ({
  label: string
  children?: never
} | {
  label?: never
  children: React.ReactNode
})

const Button = (props: IButtonProps) => {
  const { file, url, newTab, label, variant, color, size, children, ...restProps } = props

  const buttonProps: ButtonProps = {
    variant: variant || 'contained',
    color: color || 'primary',
    size: size || 'medium',
    ...restProps,
  }

  if (file) {
    return (
      <DownloadButton buttonProps={buttonProps} file={file}>
        {children || label}
      </DownloadButton>
    )
  }

  if (url) {
    return (
      <LinkButton buttonProps={buttonProps} url={url} newTab={newTab}>
        {children || label}
      </LinkButton>
    )
  }

  return (
    <MuiButton {...buttonProps}>
      {children || label}
    </MuiButton>
  )
}

export default withPromoWrapper(Button)

import { styled } from '@mui/material/styles'
import Link from '../../link'

const FooterMail = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(6),
  color: theme.palette.text.primary,
  fontSize: 16,
  lineHeight: '1em',
  fontWeight: 400,
  textDecoration: 'none',
  transition: 'color ease-in-out 0.2s',

  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

export default FooterMail

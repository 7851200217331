import { styled } from '@mui/material/styles'
import ListItem, { ListItemProps } from '@mui/material/ListItem'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { ILinkProps } from '../../link'

export const LinkText = styled(Typography)<TypographyProps & Partial<ILinkProps>>(({ theme, to }) => {
  const isTitle = !to

  return {
    fontSize: 16,
    fontWeight: isTitle ? 300 : 400,
    color: isTitle ? theme.palette.brand.brandedGray : theme.palette.text.primary,
    lineHeight: 'normal',
    textDecoration: 'none',
    transition: 'color ease-in-out .1s',

    ...(!isTitle && {
      '&:hover': {
        color: theme.palette.brand.brandedRed,
      },
    }),

    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
  }
})

export const FooterListItem = styled(ListItem, { shouldForwardProp: (props) => props !== 'isTitle' })<ListItemProps & { isTitle?: boolean }>(({ theme, isTitle }) => ({
  padding: 0,

  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(isTitle ? 6 : 4),

    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(isTitle ? 5 : 3),
    },

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(isTitle ? 4 : 2),
    },
  },
}))
